import React, {useEffect} from 'react'
import "./heart.css"
import {gsap} from "gsap"
const Heart = () => {

   useEffect(() => {
    const handleMouseMove = (event) => {
        const { clientX, clientY } = event;
        gsap.to(".heart",{
            x: clientX - 950,
            y: clientY - 430,
            ease: "power4.out"
        })
    }
    window.addEventListener("mousemove",handleMouseMove);

    return () => {
        window.removeEventListener("mousemove",handleMouseMove)
    }

   },[]) 
  return (
    <div className="heart invisible lg:visible overflow-hidden"></div>
  )
}

export default Heart