import React, { useState, useRef } from 'react';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Game = () => {
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [n1,setN1] = useState("")
  const [n2,setN2] = useState("")
  const [output, setOutput] = useState("")
  const ref = useRef()

  useGSAP(() => {

    gsap.to("#box",{

        
        borderRadius : 1000,
        scrollTrigger : {
            trigger : '#box',
            scrub: true,
        },
        
        


    })


  },[])

  const handleClick = () => {
    setLoading(true);
    setCompleted(false);
    
    if ((n1.toLowerCase() === "stanric" || n1.toLowerCase() === "vailancia") && (n2.toLowerCase() === "stanric" || n2.toLowerCase() === "vailancia")){
        setOutput("100% Compatible")
    }
    else{
        setOutput("Not a chance in 7 generations")
    }

    setTimeout(() => {
      setLoading(false);
      setCompleted(true);
    }, 5000); // 5 seconds for the loading bar to reach 100%
  };

  return (
    <div ref={ref} id='box' className="flex flex-col items-center justify-center min-h-screen bg-yellow-600 opacity-1 ">
      {!completed ? (
        <div className="w-1/2">
          <div className="mb-4">
            <h2 className="text-center text-2xl p-4">Compatibility Checker</h2>
            <input
              type="text"
              placeholder="Enter Your Name"
              className="w-full px-4 py-2 mb-4 border rounded-md"
              onChange={(e) => setN1(e.target.value)}
            />
            <input
              type="text"
              placeholder="Enter Another persons name"
              className="w-full px-4 py-2 border rounded-md"
              onChange={(e) => setN2(e.target.value)}
            />
          </div>
          <button
            onClick={handleClick}
            className="px-4 py-2 text-white bg-blue-500 rounded-md"
          >
            Submit
          </button>

          {loading && (
            <div className="relative w-full h-4 mt-4 bg-gray-300 rounded-full">
              <div
                className="absolute top-0 left-0 h-4 bg-blue-500 rounded-full animate-loading-bar"
                style={{ width: '100%' }}
              ></div>
            </div>
          )}
        </div>
      ) : (
        <div> 
        <div className="text-xl font-bold text-green-500">{output}</div>
        <button
            onClick={()=>{setCompleted(false)}}
            className="px-4 py-2 text-white bg-blue-500 rounded-md"
          >
            Try Again
          </button>
          </div>
      )}
    </div>
  );
};

export default Game;
