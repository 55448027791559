import Spline from '@splinetool/react-spline';
import { useState, useEffect, useRef } from 'react';
import './App.css';
import "./index.css"
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Polaroid from './components/Polaroid';
import Game from './components/Game';
import Heart from './components/Heart';
gsap.registerPlugin(ScrollTrigger);

function App() {
  const secondRef = useRef()
  const textRef = useRef()
  const [title] = useState(["Girlfriend", "Superwoman", "Beautiful"])
  const [currentIndex, setCurrentIndex] = useState(0);
  const timeline = gsap.timeline({
    repeat: -1, yoyo : true,
  })

  useGSAP(()=>{

    timeline.from("#title", {
      opacity: 0,
      y: 20,
    })
    timeline.to("#title", {
      opacity: 1,
      y: 0,
      ease : "power2.inOut",
      duration : 1.5,
      
    })
    timeline.to("#title", {
      opacity: 0,
      y: 20,
      
      ease : "power2.out",
      duration : 2,
      
    })
    

    
  },[currentIndex])

  useGSAP(()=>{
    gsap.from("#first-div",{
      opacity : 0,
      x: -100,
    })

    gsap.from(".sp",{
      opacity : 0,
      duration : 1.3,
      scrollTrigger : {
        trigger: '.sp',
        start: 'top bottom',
        scrub: true,
      },
      stagger : 0.8,
    })

    gsap.from(".end-text",{
      opacity : 0,
      y: 20,
      
      scrollTrigger : {
        trigger: '.end-text',
        
        
        
      },
      duration : 1,
      ease :"power2.inOut"
    })
    
  })

  useEffect(()=>{

    const el = textRef.current;
    gsap.fromTo(el,{
      opacity: 0,
      x: 500,
    },{
      opacity: 1,
      
      scrollTrigger:{
        trigger: el,
        
        start: "top 50%",
        end: "top 20%",
        
        
        scrub : true,
        
        
        
       
      },
      x:-1500,
      duration : 1,
      scale: 0.5
      
      
    })

  },[])


  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % title.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [title.length]);

  return (
    <div className="flex flex-col bg-black ">
    <div  className="flex relative flex-col  h-[100vh] justify-center items-center text-yellow-500 bg-black">
    <Spline
    className='absolute  overflow-hidden'
        scene="https://prod.spline.design/Rq8JULxWVNBfqYbU/scene.splinecode" 
      />
        <p id='first-div' className="text-4xl md:text-[100px] pointer-events-none mt-12  mb-5 ">Hey</p>
        <h1 id='title' className={`text-5xl md:text-[125px] pointer-events-none mt-12 `}>{title[currentIndex]} !</h1>
        <p className='p-4 ml-72 mt-32 -rotate-12'>ps: thats my heart evaporated for u</p>
        
      
    </div>

    <div ref={secondRef} className='text-yellow-300 flex flex-col flex-nowrap h-[40vh] sm:h-[50vh] md:h-[90vh]  overflow-hidden '>
      <p className='pt-16 pb-10 pl-3 md:pl-10 text-xl md:text-4xl'>This is my most favourite project so here it goes</p>
      <h2 className=' pl-3 md:pl-10 text-xl md:text-6xl'>You are</h2>
      <p ref={textRef} className='p-12 text-green-600 flex flex-nowrap h-auto pl-3 md:pl-10 text-8xl md:text-[200px] lg:text-[600px] overflow-hidden w-[6200px] whitespace-nowrap'>INCREDIBLE</p>
    </div>
    <div className='flex flex-col gap-2 p-3 md:px-8 text-lg md:text-3xl text-yellow-400 h-[100vh] '>
        <p className='sp'>Just how lucky I am to have you as my Girlfriend !</p>
        <p className='sp'>You make me happy when skies are grey</p>
        <p className='sp'>And you make me sigh and just keep my phone down and ghost you when your are angry. LOL</p>
        <p className='sp'>But, even then you make me feel extremely happy in the end</p>
        <p className='sp'>Maybe the old stan is back, in a modernised way, making e-cards for you</p>
        <p className='sp'>One thing though all love songs automatically put an image of you in my head now when i listen to them</p>
        <p className='sp'>Thank you for tolerating me, thanks for trying and introducing me to new food spots</p>
        <p className='sp'>Vail is the best !!!!</p>
        <p className='sp pt-16 flex items-center justify-center'>Scroll further down</p>
      </div>
      <Polaroid />

      <Game />

      <div className='flex flex-col justify-center items-center text-slate-300 text-xl h-[100vh]  md:text-4xl end-text relative overflow-hidden'>
        <p className='p-10 absolute top-[50%]'>This is probably the nerdiest card you will ever recieve. But once again Happy Girlfriends Day to you</p>
        <Heart />
        <div className='lo absolute space-x-2 overflow-hidden'>
          <span >I</span>
          <span >Love</span>
          <span >You</span>
        </div>

       
      </div>
      <div className='w-[100%] overflow-hidden'>
        
      </div> 
    </div>

    
  );

}


export default App;






