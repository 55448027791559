import { one, two, three, four, five, six, seven, eight, nine, ten, eleven } from "./assets"


export const imgs = [
    {
        url : one,
        alt : "image",
        class : "w-32 md:w-64 lg:w-72 top-2.5 left-4 bg-neutral-200 p-1 pb-4 absolute -rotate-6 drg"
    },
    {
        url : two,
        alt : "image",
        class : "w-32 md:w-64 lg:w-72 top-1 left-3.5 bg-neutral-200 p-1 pb-4 absolute rotate-1 drg"
    },
    {
        url : three,
        alt : "image",
        class : "w-32 md:w-64 lg:w-72 left-4.5 bg-neutral-200 p-1 pb-4 absolute -rotate-3 drg"
    },
    {
        url : four,
        alt : "image",
        class : "w-32 md:w-64 lg:w-72 left-4.5 top-2.5 bg-neutral-200 p-1 absolute pb-4 rotate-2 drg"
    },
    {
        url : five,
        alt : "image",
        class : "w-32 md:w-64 lg:w-72 left-6.5 top-1.5 bg-neutral-200 p-1 absolute pb-4 -rotate-5 drg"
    },
    {
        url : six,
        alt : "image",
        class : "w-32 md:w-64 lg:w-72 left-5.5 top-3.5 bg-neutral-200 p-1 absolute pb-4 rotate-4 drg"
    },
    {
        url : seven,
        alt : "image",
        class : "w-32 md:w-64 lg:w-72 left-2.5 top-5.5 bg-neutral-200 p-1 absolute pb-4 rotate-1 drg"
    },
    {
        url : eight,
        alt : "image",
        class : "w-32 md:w-64 lg:w-72 left-4.5 top-8.5 bg-neutral-200 p-1 absolute pb-4 -rotate-3 drg"
    },
    {
        url : nine,
        alt : "image",
        class : "w-32 md:w-64 lg:w-72 left-8.5 top-2.5 bg-neutral-200 p-1 absolute pb-4 -rotate-2 drg"
    },
    {
        url : ten,
        alt : "image",
        class : "w-32 md:w-64 lg:w-72 left-5.5 top-8.5 bg-neutral-200 p-1 absolute pb-4 rotate-5 drg"
    },
    {
        url : eleven,
        alt : "image",
        class : "w-32 md:w-64 lg:w-72 left-4.5 top-3.5 bg-neutral-200 p-1 absolute pb-4 -rotate-6 drg"
    },

]

