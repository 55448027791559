import React, { useEffect } from 'react';
import gsap from 'gsap';
import { Draggable } from 'gsap/Draggable';
import { imgs } from '../utils';

gsap.registerPlugin( Draggable);

const Polaroid = () => {

  useEffect(() => {
    Draggable.create(".drg", {
      
      inertia: {
        maxDuration: 1,
        minDuration: 0.5,
        resistance: 1000,
      },
      ease: 'bounce.inOut',
      bounds: ".containerz",
    });
  }, []);

  return (
    <div className="text-white h-[80vh] relative justify-center items-center bg-inherit containerz ">
      {
        imgs.map((img, i) => (
          <img key={i} className={img.class} src={img.url} alt={img.alt} />
        ))
      }
    </div>
  );
};

export default Polaroid;




 
    
